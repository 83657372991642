<template>
    <div>
        <div v-if="requestCalls > 0">
            <base-header class="pb-6">
              <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                  <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
              </div>
            </base-header>
            <div class="container-fluid mt--6">
              <div class="card mb-4">
                <div class="card-body text-center">
                  <skeleton-loading></skeleton-loading>
                </div>
              </div>
            </div>
        </div>
        <div v-else>
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card mb-4">
                            <div class="card-header mt--4 mb--4">
                                <div class="row">
                                    <div class="col">
                                    <h3 class="mb-0"> {{tt('unspsc')}}</h3>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="search" :placeholder="tt('search')" v-on:keyup="filter" />                         
                                        </div>
                                    </div>
                                    <div class="col text-right">
                                        <!-- <base-button size="sm" class="float-right" @click="showFormMainG">{{ tt('create') }}</base-button> -->
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-table
                                    :data="table.data"
                                    height="400px"
                                    style="width:100%"
                                    row-key="identity_relation_code"
                                    lazy
                                    class="table-responsive table-flush" 
                                    header-row-class-name="thead-light"
                                    :load="load"
                                    :tree-props="{children: 'children', hasChildren: 'haschildren'}"
                                >
                                    <el-table-column
                                        :label="tt('code')"
                                        sortable>
                                        <template slot-scope="scope" style="background-color:red">{{ scope.row.code}}</template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="title"
                                        :label="tt('title')"
                                        sortable
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="definition"
                                        :label="tt('definition')"
                                        sortable
                                    >
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div class="card-footer pb-0 ">   
                                <span class="float-left">
                                    <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                                </span>                             
                                <span class="float-right">
                                    {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import Api from '@/helpers/api';
import unspsc from '@/services/dictionary/unspsc.service';
export default {
    data(){
        return{
            search: '',
            requestCalls:0,
            table: {
                search: '',                    
                total: 0,
                data: [],
                page: [],
            }, 
        }
    },
    mounted(){
        this.get();
    },
    methods: {
        get(){
            let context = this;
            this.requestCalls++;
            Api(context, unspsc.get({search: this.search})).onSuccess((response) =>{
                context.table.total = response.data.data.total;
                context.table.data = response.data.data.data;
                context.table.page  = response.data.data;
            }).onError((error) =>{
                context.table.data = [];
            }).onFinish(() => {
                context.requestCalls--;
            }).call()
        },
        tableRowClassName(row) {
            if (row.level === 2) {
                return 'level-2-row';
            } else if (row.level === 3) {
                return 'level-3-row';
            }else if (row.level === 4) {
                return 'level-4-row';
            }
            return '';
        },
        // save(row){
        //     this.mescForm.mainGroup.loading = true;
        //     this.mescChild.childGroup.loading = true;
        //     let context = this;
        //     let formData = new FormData();
        //     let checkMesc = false;
        //     if((this.mescForm.mainGroup.code != '' && this.mescForm.mainGroup.code.length == 2) || ( this.mescChild.childGroup.code != '' && this.mescChild.childGroup.code.length == 2)){
        //         for(var i = 0; i < this.tableData;i++){
        //             if(this.tableData[i].code == this.mescForm.mainGroup.code){
        //                 checkMesc = true;
        //             }else{
        //                 checkMesc = false;
        //             }
        //         }
                

        //         if(checkMesc == false){
        //             if(row == null){
        //                 formData.append('parent',0);
        //                 formData.append('code', this.mescForm.mainGroup.code);
        //                 formData.append('description', this.mescForm.mainGroup.description)
        //             }else{
        //                 if(this.mescChild.childGroup.type == 'Main Group'){
        //                     formData.append('parent',1);
        //                 }else{
        //                     formData.append('parent',2);
        //                 }
        //                 formData.append('parent_id',this.mescChild.childGroup.parent_id)
        //                 formData.append('code', this.mescChild.childGroup.code);
        //                 formData.append('description', this.mescChild.childGroup.description)
        //             }

        //             Api(context, Mesc.save(formData)).onSuccess((response) =>{
        //                 context.get();
        //                 context.$notify({
        //                     message: response.data.message,                  
        //                     type: 'success',
        //                     verticalAlign: "bottom",
        //                     horizontalAlign: "left"
        //                 });
        //             }).onError((error) => {
        //                 context.$notify({
        //                     message: error.response.data.message,                  
        //                     type: 'danger',
        //                     verticalAlign: "bottom",
        //                     horizontalAlign: "left"
        //                 });
        //             }).onFinish(() =>{
        //                 context.mescForm.mainGroup.show = false;
        //                 context.mescChild.childGroup.show = false;
        //                 context.mescForm.mainGroup.loading = false;
        //                 context.mescChild.childGroup.loading = false;
        //                 context.mescChild.childGroup.code = '';
        //                 context.mescChild.childGroup.description = '';
        //                 context.mescChild.childGroup.parent_id = '';
        //                 context.mescForm.mainGroup.code = '';
        //                 context.mescForm.mainGroup.description = '';
        //             }).call()
        //         }else{
        //             this.$notify({
        //                 message: 'Code Main Group Already Exits',                  
        //                 type: 'danger',
        //                 verticalAlign: "bottom",
        //                 horizontalAlign: "left"
        //             });
        //             this.mescForm.mainGroup.show = false;
        //             this.mescChild.childGroup.show = false;
        //             this.mescForm.mainGroup.loading = false;
        //             this.mescChild.childGroup.loading = false;
        //             this.mescChild.childGroup.code = '';
        //             this.mescChild.childGroup.description = '';
        //             this.mescChild.childGroup.parent_id = '';
        //             this.mescForm.mainGroup.code = '';
        //             this.mescForm.mainGroup.description = '';
        //         }
        //     }else{
        //         this.$notify({
        //             message: 'The character in MESC Code is a Maximum of 2 ',                  
        //             type: 'danger',
        //             verticalAlign: "bottom",
        //             horizontalAlign: "left"
        //         });
        //         this.mescForm.mainGroup.loading = false;
        //         this.mescChild.childGroup.loading = false;
        //     }
        // },
        load(tree, treeNode, resolve) {
            setTimeout(() => {
                let context = this;
                let query = {level: tree.level, identity_relation_code: tree.identity_relation_code}
                Api(context, unspsc.dataChildren(query)).onSuccess((response) => {
                    resolve(response.data.data);
                }).onError((error) =>{

                }).call()
            }, 100)
        },
        filter() {
            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                this.get()
            }, 1000);
        },
    }
}
</script>

<style>
  .el-table__body > tbody .el-table__row.el-table__row--level-0{
    background: #c6c6cb;
  }
  .el-table__body > tbody .el-table__row.el-table__row--level-1{
    background: rgb(255, 220, 155);
  }

  .el-table__body >tbody .el-table__row.el-table__row--level-2 {
    background: #adff81;
  }

  .el-table__body >tbody .el-table__row.el-table__row--level-3{
    background: #acacfe;
  }
</style>